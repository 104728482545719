const listeners = new Map();
const selections = new Map();
const prevCommaCount = new Map();

const validBinding = (binding) => {
    if (binding.value?.min && isNaN(Number(binding.value.min))) {
        throw "v-number min value must be number";
    }
    if (binding.value?.max && isNaN(Number(binding.value.max))) {
        throw "v-number max value must be number";
    }
};

const notNumber = /(?!^-)[^0-9]/g;
const notDecimal = /(?!^-)[^0-9.]/g;
const systemKey = [
    "Delete",
    "Backspace",
    "Tab",
    "Esc",
    "Escape",
    "Enter",
    "Home",
    "End",
    "PageUp",
    "PageDown",
    "Del",
    "Delete",
    "Left",
    "ArrowLeft",
    "Right",
    "ArrowRight",
    "Insert",
    "Up",
    "ArrowUp",
    "Down",
    "ArrowDown",
];
const keyWithCtrl = ["a", "A", "c", "C", "x", "X", "v", "V"];
const MAXIMUM_DECIMAL_LENGTH = 10;
const DECIMAL_SEPARATOR = ".";
const THOUSAND_SEPARATOR = " ";

const keyEvent = (e, binding) => {
    const { key, ctrlKey, metaKey } = e;
    const target = e.target;
    const selectionStart = target.selectionStart || 0;
    if (systemKey.includes(key)) {
        return;
    }
    if ((ctrlKey || metaKey) && keyWithCtrl.includes(key)) {
        return;
    }
    if (target && selectionStart === 0 && target.value.includes("-")) {
        e.preventDefault();
        return;
    }
    if (key >= "0" && key <= "9") {
        return;
    }
    if (key === "-" && (binding.modifiers?.minus || binding.value?.minus) && target && selectionStart === 0 && !target.value.includes("-")) {
        return;
    }

    if (key === DECIMAL_SEPARATOR && (binding.modifiers?.point || binding.value?.point) && target && !target.value.includes(key)) {
        if (target.value.includes("-") && selectionStart <= 1) {
            e.preventDefault();
        }
        return;
    }
    e.preventDefault();
};

const inputEvent = (e, binding) => {
    const el = e.target;
    process(el, binding);
    el.dispatchEvent(
        new Event("change", {
            bubbles: true,
            cancelable: false,
            composed: true,
        })
    );
};

const process = (el, binding) => {
    selections.set(el, el.selectionStart || 0);
    processPoint(el, binding.modifiers?.point ?? binding.value?.point);
    processMinus(el, binding.modifiers?.minus ?? binding.value?.minus);
    prevCommaCount.set(el, (el.value.match(/,/g) || []).length);
};

const processPoint = (el, point) => {
    if (point) {
        el.value = el.value.replace(notDecimal, "").replace(/^(-?\d*\.?)|(\d*)\.?/g, "$1$2");
        if (el.value.indexOf(DECIMAL_SEPARATOR) === 0) {
            el.value = "0" + el.value;
            el.setSelectionRange(getSelectionStart(el) + 1, getSelectionStart(el) + 1);
        }
        if (
            el.value.includes(DECIMAL_SEPARATOR) &&
            el.value.substring(el.value.indexOf(DECIMAL_SEPARATOR)).length > MAXIMUM_DECIMAL_LENGTH
        ) {
            el.value = el.value.substring(0, el.value.indexOf(DECIMAL_SEPARATOR) + MAXIMUM_DECIMAL_LENGTH);
            el.setSelectionRange(getSelectionStart(el), getSelectionStart(el));
        }
    } else {
        el.value = el.value.replace(notNumber, "");
    }
};

const processMinus = (el, minus) => {
    if (minus) {
        const hasMinus = el.value.indexOf("-") === 0;
        el.value = el.value.replace(/-/g, "");
        if (hasMinus) {
            el.value = "-" + el.value;
            el.setSelectionRange(getSelectionStart(el), getSelectionStart(el));
        }
    } else {
        el.value = el.value.replace(/-/g, "");
    }
};

const blurEvent = (e, binding) => {
    const el = e.target;
    deleteFirstZero(el, binding);
    deleteFirstSeparator(el);
    deleteLastDecimalPoint(el);
    deleteOnlyMinus(el);
    setDefaultValue(el, binding);
    process(el, binding);

    el.dispatchEvent(
        new Event("input", {
            bubbles: true,
            cancelable: false,
            composed: true,
        })
    );
};

const deleteFirstSeparator = (el) => {
    while (el.value.startsWith(`${THOUSAND_SEPARATOR}`)) {
        el.value = el.value.substring(1);
    }
    while (el.value.startsWith(`-${THOUSAND_SEPARATOR}`)) {
        el.value = el.value.slice(0, 1) + el.value.slice(2);
    }
};

const deleteFirstZero = (el, binding) => {
    if (binding.modifiers?.firstZero || binding.value?.firstZero) return;

    while (el.value.startsWith("-0") && !el.value.startsWith(`-0${DECIMAL_SEPARATOR}`)) {
        el.value = el.value.slice(0, 1) + el.value.slice(2);
        deleteFirstSeparator(el);
    }
    while (el.value.length > 1 && el.value.startsWith("0") && !el.value.startsWith(`0${DECIMAL_SEPARATOR}`)) {
        el.value = el.value.substring(1);
        deleteFirstSeparator(el);
    }
};

const deleteLastDecimalPoint = (el) => {
    if (el.value.endsWith(DECIMAL_SEPARATOR)) {
        el.value = el.value.slice(0, -1);
    }
};

const deleteOnlyMinus = (el) => {
    if (el.value === "-") {
        el.value = "";
    }
};

const setDefaultValue = (el, binding) => {
    if (!el.value.trim()) {
        if (!binding.value?.min) return;
        el.value = String(binding.value.min);
    }
};

const getSelectionStart = (el) => selections.get(el) || 0;

export {
    listeners,
    prevCommaCount,
    validBinding,
    keyEvent,
    inputEvent,
    blurEvent,
}
