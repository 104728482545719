import {
  listeners,
  prevCommaCount,
  validBinding,
  keyEvent,
  inputEvent,
  blurEvent,
} from '@/common/directives/numberHelper';
import { replaceValue } from '@/common/directives/replaceHelper';

export const directives = {
  number: {
    // https://github.com/jongmin4943/vue3-directive-number
    // Репо директивы
    // НО
    // 1. его уже год не обновляли
    // 2. под наши нужды ее нужно было немного расширить
    // попробую дописаться до автора, или pull-request сделаю, если ответит и примет, то поставим нативно
    mounted: (el, binding) => {
      validBinding(binding);
      const keyListener = (e) => keyEvent(e, binding);
      const inputListener = (e) => inputEvent(e, binding);
      const blurListener = (e) => blurEvent(e, binding);
      listeners.set(el, {
        key: keyListener,
        input: inputListener,
        blur: blurListener,
      });
      prevCommaCount.set(el, 0);
      el.addEventListener("keydown", keyListener);
      el.addEventListener("input", inputListener);
      if (["INPUT", "TEXTAREA"].includes(el.tagName)) {
        el.addEventListener("blur", blurListener);
      } else {
        const inputElement = el.getElementsByTagName("input").item(0);
        const textAreaElement = el.getElementsByTagName("textarea").item(0);
        if (inputElement) {
          inputElement.addEventListener("blur", blurListener);
        } else if (textAreaElement) {
          textAreaElement.addEventListener("blur", blurListener);
        } else {
          throw "v-number must apply to input type of tag";
        }
      }
    },

    beforeUnmount: (el) => {
      const addedListeners = listeners.get(el);
      if (addedListeners) {
        el.removeEventListener("keydown", addedListeners.key);
        el.removeEventListener("input", addedListeners.input);
        if (["INPUT", "TEXTAREA"].includes(el.tagName)) {
          el.removeEventListener("blur", addedListeners.blur);
        } else {
          const inputElement = el.getElementsByTagName("input").item(0);
          const textAreaElement = el.getElementsByTagName("textarea").item(0);
          inputElement?.removeEventListener("blur", addedListeners.blur);
          textAreaElement?.removeEventListener("blur", addedListeners.blur);
        }
      }
    },
  },
  phone: {
    created(el, binding) {
      if (binding.value?.disabled) return
      el.onInputEvent = (event) => {
        if (!/^[0-9-+()\s]*$/.test(event.target.value)) {
          replaceValue(event, /[^0-9-+()\s]/g)
        }
      };
      el.addEventListener('input', el.onInputEvent);
    },
    unmounted(el) {
      el.removeEventListener('input', el.onInputEvent);
    }
  },
  numberPlus: {
    created(el, binding) {
      if (binding.value?.disabled) return
      el.onInputEvent = (event) => {
        if (!/^[0-9+]*$/.test(event.target.value)) {
          replaceValue(event, /[^0-9+]/g)
        }
      };
      el.addEventListener('input', el.onInputEvent);
    },
    unmounted(el) {
      el.removeEventListener('input', el.onInputEvent);
    }
  },
  clickOutside: {
    beforeMount(el, binding) {
      el.clickOutsideEvent = (evt) => {
        evt.stopPropagation();
        if (!(el === evt.target || el.contains(evt.target))) {
          binding.value(evt, el);
        }
      };
      document.addEventListener("click", el.clickOutsideEvent);
    },
    unmounted(el) {
      document.removeEventListener("click", el.clickOutsideEvent);
    },
  },
  cancelValueChangeOnWheel: {
    mounted(el) {
      el.onWheel = (evt) => {
          evt.target.blur();
      }
      el.addEventListener('wheel', el.onWheel);
    },
    unmounted(el) {
      el.removeEventListener('wheel', el.onWheel);
    }
  }
};
