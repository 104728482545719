<template>
    <AuthLayout
        v-if="isLoaded"
        shortStyle
    >
        <router-view />
    </AuthLayout>
    <BasePreloader v-else />
</template>

<script setup>
import 'bootstrap/dist/css/bootstrap.min.css';
import { ref, onMounted } from 'vue';
import 'material-design-icons/iconfont/material-icons.css';
import BasePreloader from '@/common/components/Preloader/BasePreloader';
import AuthLayout from '@/common/components/layouts/AuthLayout';
import authStore from '@/auth/store/authStore';

require('bootstrap');

const isLoaded = ref(false);

onMounted(async () => {
    await authStore.dispatch('init');
    isLoaded.value = true;
});
</script>

<style lang="css" src="@/common/assets/css/Fonts.css" />
<style lang="css" src="@/common/assets/css/App.css" />
<style lang="css" src="@/auth/assets/css/App.css" />
