import { createRouter, createWebHistory } from 'vue-router';
import { ROUTES } from '@/auth/router/routes';

const routes = [
    {
        path: '/',
        redirect: { name: ROUTES.SIGN_IN.NAME },
        component: () => import('@/auth/components/BaseAuth.vue'),
        children: [
            {
                name: ROUTES.SIGN_IN.NAME,
                path: ROUTES.SIGN_IN.PATH,
                component: () => import('@/auth/components/SignIn/SignIn.vue'),
            },
            {
                name: ROUTES.RESTORE_PASSWORD.NAME,
                path: ROUTES.RESTORE_PASSWORD.PATH,
                component: () => import('@/auth/components/SignIn/RestorePassword.vue'),
            },
            {
                name: ROUTES.SIGN_UP.NAME,
                path: ROUTES.SIGN_UP.PATH,
                component: () => import('@/auth/components/SignUp/SignUp.vue'),
                props: (route) => ({
                    invitationToken: route.query.invitation_token,
                    promoCode: route.query.promocode,
                    // TODO: убрать после полного подключения авторизации
                    testAgency: route.query.is_test_agency,
                }),
            },
        ],
    },
    {
        name: ROUTES.EMAIL_CONFIRM.NAME,
        path: ROUTES.EMAIL_CONFIRM.PATH,
        component: () => import('@/auth/components/SignUp/ConfirmEmail.vue'),
        props: (route) => ({
            confirmationToken: route.query.confirmation_token,
        }),
    },
];

const guard = async (to) => {
    if (to.matched.length === 0) {
        await router.push({ name: ROUTES.SIGN_IN.NAME });
    } else {
        return true;
    }
};

const router = createRouter({
    history: createWebHistory(process.env.VUE_APP_PUBLIC_PATH),
    routes,
});

router.beforeEach(guard);

export default router;
