const replaceValue = (event, regex) => {
    // определяем стартовую позицию курсора
    const start = event.target.selectionStart;
    event.target.value = event.target.value.replace(regex, '');
    // не двигаем курсор на шаг вперед, если буква, без этого курсор с середины текста улетает в конец текста
    event.target.setSelectionRange(start, start - 1);
    event.stopImmediatePropagation();
}

export {replaceValue}
