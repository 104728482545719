<template>
  <div
    ref="root"
    class="custom-toast d-flex align-items-center my-1 py-2 ps-2 pe-4"
    :class="{'error': props.toastData.type === 'error'}"
  >
    <div class="d-flex align-items-center w-100 position-relative">
      <span class="material-icons fs-1 me-2">
        {{ props.toastData.type === 'error' ? 'error' : 'done' }}
      </span>
      <span class="small w-100">
        {{ props.toastData.text }}
      </span>
      <button
        class="toast-btn d-flex align-items-center justify-content-center"
        @click="hideToast"
      >
        <span class="material-icons fs-5">close</span>
      </button>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted} from 'vue';

const props = defineProps({
  toastData: {
    type: Object,
    required: true
  }
})

const root = ref(null)
const emit = defineEmits(['removeToast', 'setToastHeight'])
const timeout = ref(null)

onMounted(() => {
  timeout.value = setTimeout(() => {
    emit('removeToast')
  }, props.toastData.time)
  emit('setToastHeight', root.value.getBoundingClientRect().height)
})

const hideToast = () => {
  clearTimeout(timeout.value)
  timeout.value = null
  emit('removeToast')
}

</script>

<style scoped>
.custom-toast {
  width: 350px;
  min-height: 60px;
  height: min-content;
  background-color: #0D9EFE;
  color: #FFFFFF;
  border-radius: var(--br-4);
}

.custom-toast.error {
  background-color: var(--color-error);
}

.toast-btn {
  color: #FFFFFF;
  position: absolute;
  border: none;
  background-color: transparent;
  top: -4px;
  right: -24px;
}
</style>
