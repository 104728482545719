export const ROUTES = {
    SIGN_IN: {
        NAME: 'sign_in',
        PATH: '/sign-in',
    },
    RESTORE_PASSWORD: {
        NAME: 'restore_password',
        PATH: '/sign-in/restore',
    },
    SIGN_UP: {
        NAME: 'sign_up',
        PATH: '/sign-up'
    },
    EMAIL_CONFIRM: {
        NAME: 'confirm_email',
        PATH: '/sign-up/confirm-email',
    },
};
