<template>
  <div
    class="toast-wrapper d-flex flex-column-reverse"
    :class="{'toast-removed': isRemoved}"
    :style="`height: calc(${height}px + (0.5em * ${containerHeight.length}))`"
  >
    <TransitionGroup
      class="position-relative"
      name="list"
      tag="div"
    >
      <Toast
        v-for="(toast, index) in toastList"
        :key="toast.key"
        :toastData="toast"
        :index="index"
        @removeToast=removeToast(toast)
        @setToastHeight="setHeight"
      />
    </TransitionGroup>
  </div>
</template>

<script setup>
import {ref, onMounted, computed} from 'vue';
import emitter from '@/common/emitter';
import Toast from '@/common/components/Toast/Toast.vue'

const toastList = ref([])
const isRemoved = ref(false)
const containerHeight = ref([])

const height = computed(() => containerHeight.value.reduce((a, b) => {
  return a + b
}, 0))

const setHeight = (e) => {
  containerHeight.value.push(e)
}

onMounted(() => {
  emitter.on('showToast', (item) => {
    toastList.value.unshift(item)
  })
})

const removeToast = (el) => {
  isRemoved.value = true
  setTimeout(() => {isRemoved.value = false}, 600)
  const elIndex = toastList.value.indexOf(el)
  toastList.value.splice(elIndex, 1)
  containerHeight.value.splice(elIndex, 1)
}
</script>

<style scoped>
.toast-wrapper {
  z-index: 9999;
  max-height: var(--page-height);
  min-width: 350px;
  width: min-content;
  position: absolute;
  overflow: hidden;
  bottom: 0;
  right: 0;
  padding-right: 5px;
  transition: height 0.3s ease;
}

.toast-removed {
  transition: height 0.6s ease;
}

.list-move,
.list-enter-active,
.list-leave-active {
    transition: all 0.6s ease;
}

.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: translateX(100%);
}

.list-leave-active {
    position: absolute;
    z-index: -1;
}

</style>
